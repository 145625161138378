@use "mixins" as *
.bannerSlider
    & :global
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide > div,
        .slick-slide > div > div
            height: 100%

.banner
    position: relative
    & :global
        .banner-item
            display: flex
            position: relative
            overflow: hidden
            max-height: 100%
            max-width: 100%
            align-items: unset
            height: auto
            background: transparent
            padding: 0
            border-radius: initial

        .banner-item-label
            border-bottom-right-radius: 12px
            padding: 4px 12px
            color: rgba(var(--secondaryColor))
            text-transform: uppercase
            position: absolute
            top: 0
            left: 0
            font-size: 14px
            line-height: 13px
            height: 24px !important
            @include flex
            justify-content: center
            @include bold

.bannerInfo
    width: 100%
    position: absolute
    left: 0
    bottom: 14px
    z-index: 1
    color: rgba(var(--secondaryColor))
    padding: 0 12px
    pointer-events: none

    :global(.imageNext)
        min-width: 35px

    > p
        margin: 15px auto 0 10px
        display: flex
        flex-direction: column
        justify-content: center
        overflow: hidden

        > span:first-of-type
            font-size: 14px
            line-height: 14px
            @include bold
            overflow: hidden
            text-overflow: ellipsis
            text-transform: uppercase

        span:nth-child(2)
            font-size: 13px
            line-height: 15px
            margin-top: 5px

.casinoGamePlayButtons
    opacity: 1
    top: unset
    bottom: 0
    flex-direction: row
    > button
        margin: 0 6px!important
