@use "mixins" as *
.login
  padding: 0 16px 16px
  position: relative
  transition: height .2s, width .2s

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
    -webkit-box-shadow: 0 0 0 var(--login_fieldHeight) var(--login_fieldBg) inset
    -webkit-text-fill-color: rgb(var(--secondaryColor))
    border-bottom-color: var(--login_fieldBg)

  > div:first-of-type
    height: 44px
    @include flex
    color: rgb(var(--secondaryColor))
    font-size: 14px
    @include bold
    border-bottom: var(--login_headerBorder)
    :global(.imageNext)
      margin: 0 auto

  form
    > div:not(.loginHeader)
      > div
        margin-bottom: 26px
        position: relative

        input,
        :global(.phone-widget)
          background: var(--login_fieldBg)
          border-radius: var(--base_borderRadius)
          border: 1px solid var(--login_fieldBg)
          height: var(--login_fieldHeight)
          padding: 0 24px 0 7px
          color: rgb(var(--secondaryColor))

        :global(.viewPass)
          top: calc(50% - 5px)
          right: 6px
          background-color: rgb(var(--secondaryColor))

    :global
      .field-error
        bottom: -16px
        position: absolute
        top: unset
        @include ellipsis
        width: 100%

      .intl-tel-input
        width: 100%
        @include flex

        .selected-flag
          background: var(--login_fieldBg) !important

        .flag-container
          height: var(--login_fieldHeight)
          &:hover
            .selected-flag
              color: rgb(var(--secondaryColor))
            .arrow
              background-color: rgb(var(--secondaryColor))

        .country-list
          top: calc(var(--login_fieldHeight) + 2px)
          max-height: 104px
          border: 0

  label,
  :global(.intl-tel-input-label)
    text-transform: unset
    margin-bottom: 6px
    font-size: var(--button_fontSize)
    color: rgb(var(--primaryColor))
    padding-left: 7px

  :global(.loader)
    background: var(--login_loaderBg)
    top: 0
    left: 0
    z-index: 4

.loginHeader
  @include flex
  justify-content: center
  margin: 6px 0 16px

  :global
    .ui-switch-container
      display: inline-flex
      background: var(--uiSwitch_containerBg)
      border-radius: 30px
      border: 1px solid rgba(#000, 0.5)
      height: 38px
      overflow: hidden
      margin: 0
      padding: 1px

    .ui-switch
      margin: 0 10px 0
      width: 40px
      height: 20px

      .ui-switch-slider
        padding: 2px 7px 2px 3px
        width: 40px
        height: 20px
        border-radius: 30px

        &:before
          width: 14px
          height: 14px
          left: 3px
          bottom: 3px

.iconGif
  width: 34px
  height: 34px
  @include flex
  justify-content: center
  border-radius: 100%
  border: 2px solid var(--uiSwitch_itemBorder)
  background: var(--uiSwitch_containerBg)
  overflow: hidden
  transition: all 0.3s

  :global(.svg-icon) path
    transition: all 0.3s

  &.active
    border-color: rgb(var(--thirdaryColor))
    background: var(--uiSwitch_itemActiveBg)

    &:first-of-type
      border-color: var(--uiSwitch_itemLeftActiveBorder)

      :global(.svg-icon) path
        fill: rgb(var(--thirdaryColor))

.forgotPass
  color: var(--login_forgotPassColor)
  font-size: var(--base_fontSize)
  position: absolute
  right: 14px
  margin-top: calc((var(--login_fieldHeight) + 45px) * -1)

  &:hover
    color: var(--login_forgotPassHoverColor)
    @include pointer

.bottomContainer
  @include flex

  > button
    flex: 1
    @include ellipsis
    height: var(--login_fieldHeight)
    font-size: var(--base_fontSize)
    display: -webkit-inline-box

.loginBtn
  background: var(--loginBtn_bg)
  color: var(--loginBtn_color)
  &:hover
    background: var(--loginBtn_hoverBg)
  &:active
    background: var(--loginBtn_activeBg)
  + button
    margin-left: 12px
    background: var(--signUpBtn_bg)
    color: var(--signUpBtn_color)
    &:hover:not(:active)
      background: var(--signUpBtn_hoverBg)
    &:active
      background: var(--signUpBtn_activeBg)

.login2fa
  padding-top: 20px

  > p
    line-height: 16px

.twoFactorCodeField
  margin-top: 16px

.resendTxt
  margin: 24px 0 14px

.loginError
  color: rgb(var(--particularColor2))
  margin: -20px 0 8px
  display: block

.loginUserBlocked
  display: flex
  flex-direction: column
  margin-top: 24px
  > div
    line-height: 18px
    padding: 0 6px
    @include flex
    flex-direction: column
    justify-content: center
    text-align: center
    font-size: 13px
    > p:nth-child(2)
      margin-top: 10px
    span
      color: rgb(var(--thirdaryColor))

  button
    width: 100%
    margin-top: 28px
