@use "mixins" as *
.gameItem
  border: var(--casinoCompact_gameItemBorder)
  border-radius: var(--casinoCompact_gameItemBorderRadius)
  @include flex
  justify-content: center
  justify-self: center
  position: relative

  > div
    width: 100%
    height: 100%
    position: relative
    overflow: hidden
    border-radius: 4px
    box-shadow: 0 0 var(--casino_gameItemShadowSize) rgba(0, 0, 0, 0.4)
    background: var(--casino_gameItemBg)
    display: flex
    justify-content: center
    transition: all 0.3s

    :global(.casino-drops-wins)
      position: absolute
      left: 0
      top: -1px
      z-index: 1

    .gameItemImg :local(img)
      min-width: 100%
      min-height: 100%
      object-fit: cover

    .gameItemImg,
    > video
      transition: transform 0.3s
      width: 100%
      position: relative

    > video
      object-fit: cover

    &:before
      content: ""
      background: rgba(21, 29, 42, 0.4)
      position: absolute
      width: 100%
      height: var(--casinoCompact_gameItemBackdropHeight)
      opacity: 0
      z-index: 1
      transition: all 0.3s

  .playBtn:after
    content: ""
    background: var(--icon-casino-play) no-repeat center
    width: 112px
    height: 134px
    position: absolute
    left: 50%
    top: 42%
    transform: translate(-50%, -50%)
    opacity: 0
    z-index: 1
    transition: all 0.2s 0.15s

  &:hover
    > div
      box-shadow: 0 0 calc(var(--casino_gameItemShadowSize) * 2) rgba(0, 0, 0, 0.6)
      @include pointer

      &:before,
      &:after
        opacity: 1

      &:active:after
        opacity: 0.8

      .gameItemImg,
      > video
        transform: scale(1.04)

      .btnsContainer
        opacity: 1

.gameLabel
  position: absolute
  right: -3px
  top: 16%
  z-index: 2
  transition: all 0.2s
  display: flex
  flex-direction: column
  justify-content: flex-end

  > span
    text-transform: uppercase
    @include flex
    justify-content: center
    width: 32px
    height: 18px
    border-radius: 0 2px 2px 0
    margin-top: 6px !important
    position: relative
    background: var(--tagItemColor)

    > object
      height: 14px

    &:before,
    &:after
      content: ""
      width: 0
      height: 0
      border-style: solid
      position: absolute
      left: -6px
    &:before
      border-width: 0 6px 9px 0
      border-color: transparent var(--tagItemColor) transparent transparent
      top: 0
    &:after
      border-width: 0 0 9px 6px
      border-color: transparent transparent var(--tagItemColor) transparent
      bottom: 0
    &.icon-casino-new
      --tagItemColor: #20a814
    &.icon-casino-popular
      --tagItemColor: #f1ce0f
    &.icon-casino-hot
      --tagItemColor: #dc3438
    &.icon-casino-top
      --tagItemColor: #106fe0

.unstarred
  right: 8px
  top: 50%
  transform: translateY(-50%)
  position: absolute
  z-index: 1
  @include flex
  justify-content: center
  &::before
    content: ""
    background-color: var(--casinoCompact_gameItemStarFavoriteFill)
    mask-image: var(--icon-favorite)
    min-width: 16px
    height: 16px

  &:hover,
  &.starred
    @include pointer
    &:before
      background: var(--star_favoriteActiveFill) !important

.gameItemName
  position: absolute
  width: 100%
  height: 24px
  bottom: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  text-transform: uppercase
  font-size: var(--casino_gameItemNameFontSize)
  line-height: calc(var(--casino_gameItemNameFontSize) + 1px)
  text-align: var(--casino_gameItemNameAlign)
  padding: var(--casinoCompact_gameItemNamePadding)
  background: var(--casinoCompact_gameItemNameBg)
  color: rgba(var(--secondaryColor))
  @include bold
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  > span
    @include ellipsis
    margin-bottom: 4px
    color: rgb(var(--secondaryColor))

.btnsContainer
  position: absolute
  z-index: 2
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  transition: all 0.3s ease-in-out
  @include flex
  flex-direction: column
  opacity: 0

  button
    text-transform: uppercase
    min-width: 110px
    height: 32px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5)
    &:nth-child(2)
      margin-top: 12px

  .playNowBtn
    background: var(--casinoCompact_playNowBtnBg)
    color: var(--casino_providerActiveColor)

    &:hover
      background: var(--casinoCompact_playNowBtnHoverBg)

    &:active
      background: var(--casinoCompact_playNowBtnActiveBg)
