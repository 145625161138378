@use "mixins" as *
.header
  background: var(--header2_bg)
  border-bottom: 1px solid var(--base_borderColor)
  height: var(--header2_height)
  padding: var(--header2_padding)
  @include flex
  justify-content: space-between
  position: relative

  --verificationReminderLeft: 24px
  --verificationReminderBottom: -2px

  + *
    --header_height: var(--header2_height)

  > a:first-of-type
    min-width: var(--header_logoWidth)
    margin-top: var(--header2_logoMargin)

  > nav
    align-items: center
    min-width: auto
    height: auto
    margin-top: var(--header2_menuMargin)
    > a
      height: var(--header2_menuItemHeight)
      text-transform: var(--base_textTransform)
      padding: 0 10px
      border-radius: 4px
      @include bold
      font-size: var(--header2_menuItemFontSize)

      &:not(:first-of-type)
        margin-left: 16px

      &:hover
        background: rgba(var(--header2_menuItemBg), 0.5)
        color: rgb(var(--secondaryColor))

        & :global
          .svg-sport-icon
            svg
              path
                fill: rgb(var(--secondaryColor)) !important

            .sport-icon-bg
              fill: transparent !important

    :global
      .header-menu-item-active
        background: rgba(var(--header2_menuItemBg), 0.8)
        color: var(--header2_menuItemActiveColor)

        &:after
          content: ""
          background: rgb(var(--thirdaryColor))
          width: 100%
          height: var(--header2_menuItemActiveBorderHeight)
          position: absolute
          right: 0
          bottom: var(--header2_menuItemActiveBorderPosition)

        .svg-sport-icon
          svg
            path
              fill: rgb(var(--thirdaryColor)) !important

          .sport-icon-bg
            fill: transparent !important

      .svg-sport-icon
        height: auto
        margin-right: 10px
        display: var(--header2_menuItemIconDisplay)

        span
          @include flex

          svg
            width: auto
            min-height: 16px

        .sport-icon-bg
          fill: transparent !important

  @media only screen and (min-width: 1480px)
    > nav
      position: absolute
      transform: translateX(-50%)
      left: 50%

  @media only screen and (max-width: 1190px)
    padding-left: 14px
    > nav a
      padding: 0 8px
      :global(.svg-sport-icon)
        margin-right: 6px
        svg
          height: 14px

      &:not(:first-of-type)
        margin-left: 6px

  @media only screen and (max-width: 1000px)
    > nav
      position: absolute
      left: calc(var(--header_logoWidth) + 20px)

  :global(.site-language-container)
    @include flex

    > span:first-of-type
      margin: -2px 14px 0

    > div
      :global(.svg-icon)
        margin: 0 !important
        svg
          width: auto
          height: var(--header2_languagesFlagHeight)

      > div
        padding: 2px 8px
        min-height: var(--header2_languagesBtnHeight)
        min-width: auto
        background: var(--header2_languagesBg)
        border-radius: var(--base_borderRadius)
      > section ul li
        justify-content: center

.rightPanel
  padding: var(--header2_rightPanelPadding)
  display: flex
  flex-direction: column
  align-items: flex-end
  justify-content: space-between
  height: var(--header2_height)

  :global(.user-panel-links)
    position: absolute !important
    bottom: var(--header2_rightPanelUserLinksBottomPosition)
    right: var(--header2_rightPanelUserLinksRightPosition)
    padding-right: 10px

    button:first-child:after
      content: none

    a svg,
    button:not(:first-child) svg
      height: 16px
      width: auto

    &:after
      content: ""
      height: 15px
      border-right: var(--header_loginPanelSeparator)
      position: absolute
      right: 0
      margin-top: 1px
