@use "mixins" as *
.casinoScrollContainer
  height: calc(100% - var(--header_height))
  background: var(--casino_pageBg)
  position: relative
  overflow: hidden

  > div:first-of-type
    position: relative
    padding-right: var(--scroll_width)

.casinoContainer
  margin: 0 auto
  font-size: 13px
  max-width: 1182px
  padding: 0 8px
  width: 100%

.subHeader
  margin: 0 -10px 10px
  min-height: 48px
  background: var(--casino_pageBg)
  padding: 0 10px
  position: sticky
  top: -1px
  z-index: 6
  > div
    background: var(--casino_subHeaderBg)
    border-radius: 6px
    padding: 0 12px 0 4px
    width: 100%
    @include flex

.liveCasinoTabs
  @include flex
  position: absolute
  left: 50%
  transform: translate(-50%, -50%)
  top: 50%
  border-radius: 3px
  overflow: hidden

  > button
    padding: 0 8px
    min-width: 74px
    background: var(--casino_liveTabBg)
    text-transform: uppercase
    border-radius: 0
    @include flex
    justify-content: center
    height: 26px
    color: rgba(var(--primaryColor), 0.7)
    @include bold

    &:not(.active)
      border: 1px solid rgba(0,0,0,.7)

      &:hover
        color: rgb(var(--primaryColor))

    &.active
      background: var(--casino_liveTabActiveBg)
      color: var(--button_color)
      cursor: default !important
      pointer-events: none

.nav
  list-style: none
  display: flex
  align-items: flex-end
  justify-content: center
  height: 48px
  margin-right: auto

  > *:first-of-type svg
    width: 16px
    height: auto

  > a,
  > button
    display: flex
    align-items: flex-end
    justify-content: center
    padding: 0 2px
    color: rgb(var(--primaryColor))
    height: 100%
    margin: 0 8px
    position: relative
    min-width: 60px
    background: transparent

    > span:not(:global(.svg-icon))
      font-family: var(--specialFontBoldCondensed)
      text-transform: uppercase
      @include bold
      margin-bottom: 5px

    &.active
      opacity: 1
      color: rgb(var(--secondaryColor))
      cursor: default !important

      &::after
        content: ""
        height: 2px
        width: 80%
        background: rgb(var(--thirdaryColor))
        left: 50%
        transform: translateX(-50%)
        position: absolute
        bottom: -2px

      :global(.svg-icon) svg path
        opacity: 1

    &:hover:not(.active)
      @include pointer
      color: rgb(var(--secondaryColor))

      :global(.svg-icon) svg path
        opacity: 1

    :global(.svg-icon)
      @include flex
      position: absolute
      bottom: 24px
      left: 50%
      transform: translateX(-50%)

      :global(.slots-icon-bg),
      svg path
        fill: rgb(var(--thirdaryColor))
        opacity: .8

.leftMenu
  background: var(--casino_leftNavBg)
  min-width: 66px
  width: 66px
  border-radius: 6px
  position: sticky
  top: 58px
  float: left
  display: flex
  flex-direction: column
  padding: 0 3px

  .favoritesCount
    position: absolute
    min-width: 15px
    height: 12px
    border-radius: 2px
    color: var(--casino_providerActiveColor)
    background: var(--casino_providerActiveBg)
    right: 2px
    top: 2px
    @include flex
    justify-content: center
    @include bold
    font-size: 9px
    font-style: normal

  > *
    @include flex
    flex-direction: column
    justify-content: center
    border-radius: 5px
    color: rgba(var(--secondaryColor,.85))
    min-height: 50px
    padding: 6px 2px
    position: relative
    margin-top: 10px
    font-size: 12px
    text-transform: uppercase

    &:last-child
      margin-bottom: 10px

    > span:not(:global(.svg-icon))
      line-height: 11px
      text-align: center
      margin-top: 6px
      text-overflow: ellipsis
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      font-family: var(--specialFontBoldCondensed)
      line-height: 13px

    &:nth-child(4n+1) :global(.svg-icon) path
      fill: #FFD801

    &:nth-child(4n+2) :global(.svg-icon) path
      fill: #0F72E5

    &:nth-child(4n+3) :global(.svg-icon) path
      fill: #CC3E3E

    &:nth-child(4n+4) :global(.svg-icon) path
      fill: #5BCE60

    &:hover
      color: rgb(var(--secondaryColor))
    &.active
      background: var(--casino_providerActiveBg)
      color: var(--casino_providerActiveColor)
      :global(.svg-icon) path
        fill: var(--casino_providerActiveColor)
      .favoritesCount
        background: var(--casino_providerActiveColor)
        color: var(--casino_providerActiveBg)

.casinoSearch
  margin-left: 12px
  min-height: 26px
  min-width: 30px
  position: relative
  &::before
    content: ""
    position: absolute
    height: 25px
    border-left: 1px dotted var(--base_verticalSeparator)
    left: -12px

.casinoGamesContainer
  position: relative
  width: 100%
  min-height: calc(100vh - var(--header_height) - 400px)
  max-width: calc(100% - 76px)
  margin-left: auto

  :global(.no-game)
    min-height: 40px
    position: absolute
    left: 50%
    top: 30%
    transform: translate(-50%, -50%)
