@use "mixins" as *
.casinoHeader
  display: flex
  background: var(--casino_pageBg)
  position: relative
  z-index: 1

.casinoBanner
  max-width: 870px
  width: 100%
  height: 224px
  margin: 8px 8px 8px 0
  overflow: hidden

.casinoBanner > div,
.topRightBanner > div
  width: 100%
  height: 100%
  position: relative
  border-radius: 6px
  z-index: 1
  overflow: hidden

  & :global
    .slick-slide > div > div div
      @include flex

    .banner-item
      border-radius: 6px

      .imageNext
        border-radius: 6px
        width: 100%
        overflow: hidden
        align-items: center
        justify-content: center

.topRightBanner
  @include flex
  min-width: 288px
  height: 224px
  margin: 8px 0
  overflow: hidden