@use "mixins" as *
.loading
  width: 360px
  position: static
  height: 387px
  background: var(--register_bg)
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4)
  border-radius: 8px

.loadingDetailsSignup
  height: 578px

.loadingCpfSignup
  height: 199px
