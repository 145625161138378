@use "mixins" as *
.block
  background: var(--betlip_itemBg)
  border: 1px solid var(--betslip_historyBetHeaderBg)
  border-radius: 4px
  margin-bottom: 10px
  overflow: hidden
  max-height: 48px
  transition: max-height .4s
  &.opened
    max-height: 500px

  button
    min-width: 64px
    text-transform: uppercase

.verifiedView
  @include flex
  padding: 5px 6px 5px 10px
  min-height: 38px

  > span
    @include flex
    @include fadeIn(.7s)

    svg
      width: 18px
      height: 14px

    &.iconVerified
      margin: 0 8px
      path
        fill: #5cba60

.userInfo
  @include flex
  flex: 1
  @include bold
  color: rgb(var(--secondaryColor))
  letter-spacing: 1px

  > span
    @include flex
    @include fadeIn(.7s)
    margin-right: 10px

    svg
      width: 20px
      height: auto
      path
        fill: #1A92DA

.verifyBtn
  background: #DA3A38
  border-radius: 2px
  font-size: 12px
  color: rgb(var(--secondaryColor))
  &:hover
    background: #CF2B2F
  &:active
    background: #B9191C

.verifyForm
  padding: 10px
  background: var(--betslip_stakeRowBg)
  border-top: 1px solid var(--betslip_historyBetHeaderBg)

.verificationTxt
  line-height: 16px
  color: rgb(var(--secondaryColor))
  margin-bottom: 12px

.verifyFormFields
  composes: verifyFormFields from "../../verification/item/verification-item.module.sass"
  --common_verification-inputBorder: var(--betslip_historyBetHeaderBg)
  input
    background: var(--field_bg)
    color: rgb(var(--secondaryColor))

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active
      background: var(--betslip_stakeRowBg)!important
      box-shadow: inset 0 0 0 30px var(--betslip_stakeRowBg)
      -webkit-text-fill-color: rgb(var(--secondaryColor))
      border: 1px solid var(--common_verification-inputBorder)
      color: rgb(var(--secondaryColor))
  .verifyBtn
    background: #5cba60

    &:hover
      ackground: #4bae4f

    &:active
      background: #3fa143

  .requestCodeBtn, .verifyBtn
    height: 30px
    flex: 1

.wrongTxt
  color: rgb(var(--particularColor2))
  margin: 4px 0

.resendTxt
  color: rgb(var(--secondaryColor))
  margin: 6px 0 0
